import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "offcanvas-body" }

import FlightDetail from '@/components/flights/FlightDetail.vue';
import HereMap from '@/components/map/HereMap.vue';
import { Configuration } from '@/config';
import { Offcanvas } from 'bootstrap';
import { ref, onMounted } from 'vue';

//Reference to the sidebar HTML div

export default /*@__PURE__*/_defineComponent({
  __name: 'LiveRadar',
  setup(__props) {

const sidebar = ref();

const mapComponent = ref();

const apiKey = Configuration.value('hereApiKey');

const isMobile = ref();

const selectedFlight = ref<string>();

onMounted(() => {
  isMobile.value = window.innerWidth < 768;

  const sidebarElement = document.getElementById('sidebar');
  if (sidebarElement) {
    sidebarElement.addEventListener('hide.bs.offcanvas', () => {
      if (mapComponent.value) {
        mapComponent.value.unselectFlight();
      }
    });
  }
});

const toggleSidebar = () => {
  const offcanvas = new Offcanvas(sidebar.value);
  offcanvas.toggle();
};

const showFlightDetails = (flightId: string) => {
  selectedFlight.value = flightId;
  toggleSidebar();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([{ 'offcanvas-start': !isMobile.value, 'offcanvas-bottom': isMobile.value }, 'offcanvas']),
      "data-bs-backdrop": "false",
      tabindex: "-1",
      id: "sidebar",
      "aria-labelledby": "offcanvasExampleLabel",
      ref_key: "sidebar",
      ref: sidebar
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "offcanvas-header" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn-close",
          "data-bs-dismiss": "offcanvas",
          "aria-label": "Close"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(FlightDetail, { "flight-id": selectedFlight.value }, null, 8, ["flight-id"])
      ])
    ], 2),
    _createVNode(HereMap, {
      apikey: _unref(apiKey),
      lat: "46.8",
      lng: "8.15",
      width: "100%",
      height: "1600px",
      aerialOverview: true,
      peridicallyRefresh: true,
      onOnMarkerClicked: _cache[0] || (_cache[0] = ($event: any) => (showFlightDetails($event))),
      ref_key: "mapComponent",
      ref: mapComponent
    }, null, 8, ["apikey"])
  ], 64))
}
}

})