import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container text-center" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = {
  key: 0,
  class: "row"
}
const _hoisted_12 = { class: "col" }

import DetailField from '@/components/flights/DetailField.vue';
import { Flight, Aircraft, TerrestialPosition } from '@/model/backendModel';
import { FlightRadarService } from '@/services/flightRadarService';
import { computed, inject, watch, ref, onMounted, onBeforeUnmount } from 'vue';
import { silhouetteUrl } from '@/components/aircraftIcon';


export default /*@__PURE__*/_defineComponent({
  __name: 'FlightDetail',
  props: {
  flightId: String,
},
  setup(__props) {

const props = __props;

const flight = ref<Flight>();
const aircraft = ref<Aircraft>();
const currentPosition = ref<TerrestialPosition | null>(null);
let positionUpdateInterval: number | null = null;
// Track active WebSocket connection
let currentFlightId: string | null = null;

const frService = inject('frService') as FlightRadarService;

// Set up WebSocket for position updates
const setupPositionWebSocket = (flightId: string) => {
  frService.registerFlightPositionsCallback(flightId, (positions: Array<TerrestialPosition>) => {
    // Only update if this is still the selected flight
    if (props.flightId === flightId && positions && positions.length > 0) {
      // Always use the most recent position from the array
      const latestPosition = positions[positions.length - 1];
      if (latestPosition && latestPosition.alt !== undefined) {
        currentPosition.value = latestPosition;
      }
    }
  });
};

const updateCurrentPosition = () => {
  if (!props.flightId) return;

  const requestedFlightId = props.flightId;

  try {
    // First check if we have a live position from the main WebSocket
    const livePosition = frService.getCurrentPosition(requestedFlightId);

    // Only update if this is still the selected flight
    if (props.flightId === requestedFlightId && livePosition && livePosition.alt !== undefined) {
      currentPosition.value = livePosition;
      return;
    }

    // Fallback: try to get historical positions if live data doesn't have altitude
    frService.getPositions(requestedFlightId).subscribe({
      next: (positions) => {
        // Only update if this is still the selected flight
        if (props.flightId === requestedFlightId && positions && positions.length > 0) {
          const latestPosition = positions[positions.length - 1];

          if (latestPosition && latestPosition.alt !== undefined) {
            currentPosition.value = latestPosition;
          }
        }
      },
      error: (error) => {
        console.error('Error fetching current position:', error);
      },
    });
  } catch (error) {
    console.error('Error fetching current position:', error);
  }
};

watch(
  () => props.flightId,
  (newValue, _oldValue) => {
    // Clear current position when changing flights
    currentPosition.value = null;

    // Disconnect any existing WebSocket
    if (currentFlightId) {
      frService.disconnectFlightPositionsWebSocket(currentFlightId);
      currentFlightId = null;
    }

    if (newValue) {
      // Reset references
      flight.value = undefined;
      aircraft.value = undefined;

      // Fetch new flight data using observables
      frService.getFlight(newValue).subscribe({
        next: (flightData) => {
          flight.value = flightData;

          if (flightData && flightData.icao24) {
            // Once we have the flight, get the aircraft data
            frService.getAircraft(flightData.icao24).subscribe({
              next: (aircraftData) => {
                aircraft.value = aircraftData;

                // Set up WebSocket for position updates
                currentFlightId = newValue;
                setupPositionWebSocket(newValue);

                // Get the current position (might be available immediately)
                updateCurrentPosition();
              },
              error: (err) => {
                console.error(err);
                aircraft.value = undefined;
              },
            });
          }
        },
        error: (err) => {
          console.error(err);
          flight.value = undefined;
        },
      });
    }
  },
);

onMounted(() => {
  // Get initial position data and set up WebSocket if needed
  if (props.flightId) {
    updateCurrentPosition();
    if (!currentFlightId) {
      currentFlightId = props.flightId;
      setupPositionWebSocket(props.flightId);
    }
  }

  // Maintain the interval as a fallback, but make it less frequent
  positionUpdateInterval = window.setInterval(() => {
    if (props.flightId && !currentPosition.value) {
      updateCurrentPosition();
    }
  }, 5000); // Fallback check every 5 seconds if no position is available
});

onBeforeUnmount(() => {
  // Clean up interval
  if (positionUpdateInterval) {
    window.clearInterval(positionUpdateInterval);
    positionUpdateInterval = null;
  }

  // Disconnect WebSocket
  if (currentFlightId) {
    frService.disconnectFlightPositionsWebSocket(currentFlightId);
    currentFlightId = null;
  }
});

const currentAltitude = computed(() => {
  if (currentPosition?.value?.alt !== undefined && currentPosition.value.alt !== null && currentPosition.value.alt >= 0) {
    return `${currentPosition.value?.alt.toLocaleString()} ft`;
  }

  return undefined;
});

const typeLabel = computed(() => {
  return `Type (${aircraft.value?.icaoType ? aircraft.value.icaoType : 'Type'})`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(DetailField, {
          label: "Callsign",
          text: flight.value ? flight.value.cls : null
        }, null, 8, ["text"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(DetailField, {
          label: "Silhouette",
          imageUrl: _unref(silhouetteUrl)(aircraft.value && aircraft.value.icaoType ? aircraft.value.icaoType : '')
        }, null, 8, ["imageUrl"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(DetailField, {
          label: "Registraton",
          text: aircraft.value ? aircraft.value.reg : null
        }, null, 8, ["text"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(DetailField, {
          label: "24 bit address",
          text: flight.value && flight.value.icao24 ? flight.value.icao24.toUpperCase() : null
        }, null, 8, ["text"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(DetailField, {
          label: typeLabel.value,
          text: aircraft.value ? aircraft.value.type : null
        }, null, 8, ["label", "text"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(DetailField, {
          label: "Operator",
          text: aircraft.value ? aircraft.value.op : null
        }, null, 8, ["text"])
      ])
    ]),
    (currentAltitude.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(DetailField, {
              label: "Current Altitude",
              text: currentAltitude.value
            }, null, 8, ["text"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})