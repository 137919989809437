import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"position":"relative","height":"45px"} }
const _hoisted_2 = { class: "labelText" }
const _hoisted_3 = { class: "valueText" }
const _hoisted_4 = ["src"]

import { computed } from 'vue';
import _ from 'lodash';


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailField',
  props: ['label', 'text', 'imageUrl'],
  setup(__props) {

const props = __props;

const textTruncated = computed(() => {
  return _.truncate(props.text, { length: 42 });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.label), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(textTruncated.value), 1),
    (__props.imageUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "valueText",
          src: __props.imageUrl,
          height: "20px"
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}
}

})