import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "offcanvas-body" }

import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Configuration } from '@/config';
import { Offcanvas } from 'bootstrap';
import HereMap from '@/components/map/HereMap.vue';
import FlightDetail from '@/components/flights/FlightDetail.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FlightView',
  setup(__props) {

const apiKey = Configuration.value('hereApiKey');

//Reference to the sidebar HTML div
const sidebar = ref();

const flightId = ref();

const isMobile = ref();

onMounted(() => {
  isMobile.value = window.innerWidth < 768;
  const route = useRoute();
  flightId.value = route.params.flightId;

  const offcanvas = new Offcanvas(sidebar.value);
  offcanvas.toggle();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([{ 'offcanvas-start': !isMobile.value, 'offcanvas-bottom': isMobile.value }, 'offcanvas']),
      "data-bs-backdrop": "false",
      tabindex: "-1",
      id: "sidebar",
      ref_key: "sidebar",
      ref: sidebar
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "offcanvas-header" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn-close",
          "data-bs-dismiss": "offcanvas",
          "aria-label": "Close"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(FlightDetail, { flightId: flightId.value }, null, 8, ["flightId"])
      ])
    ], 2),
    _createVNode(HereMap, {
      apikey: _unref(apiKey),
      lat: "46.8",
      lng: "7.15",
      aerialOverview: false,
      highlightedFlightId: flightId.value,
      peridicallyRefresh: false,
      ref: "mapComponent"
    }, null, 8, ["apikey", "highlightedFlightId"])
  ], 64))
}
}

})